'use client'

import React, { useState, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Image from '@/components/common/Image';
import styles from './Carousel.module.scss';

interface CarouselImage {
  url: string;
  description: string;
  height: number;
  width: number;
}

interface CarouselProps {
  images: CarouselImage[];
  autoScrollDelay?: number;
}

const Carousel: React.FC<CarouselProps> = ({ images, autoScrollDelay = 5000 }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    };

    emblaApi.on('select', onSelect);
    onSelect();

    const autoScroll = setInterval(() => {
      if (emblaApi.canScrollNext()) {
        emblaApi.scrollNext();
      } else {
        emblaApi.scrollTo(0);
      }
    }, autoScrollDelay);

    return () => {
      emblaApi.off('select', onSelect);
      clearInterval(autoScroll);
    };
  }, [emblaApi, autoScrollDelay]);

  return (
    <div className={styles.carousel}>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {images.map((image, index) => (
            <div className={styles.slide} key={index}>
              <Image
                src={image.url}
                alt={image.description}
                width={image.width}
                height={image.height}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.dots}>
        {images.map((_, index) => (
          <button
            key={index}
            className={`${styles.dot} ${index === selectedIndex ? styles.selected : ''}`}
            onClick={() => emblaApi?.scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
